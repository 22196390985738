<template>
  <b-modal id="addNewRole" title="Add New Role" @hidden="resetInfoModal" no-close-on-backdrop scrollable ok-title="Save" @ok="submitForm" size="xl">
    <b-container class="mt-1">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="3">
              <b-form-group :class="validation ? 'is-valid' : 'is-invalid'" label="Role Name:">
                <validation-provider #default="{ errors }" name="Role Name" rules="required">
                  <b-form-input v-model="role.name" list="input-list" placeholder="Enter Role Name" :state="validation" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="9" style="padding-left: 0px!important">
              <b-form-group :class="validation ? 'is-valid' : 'is-invalid'" label="Role Description:">
                <validation-provider #default="{ errors }" name="Role Description:" rules="required">
                  <b-form-input v-model="role.description" list="input-list" placeholder="Enter Role Description" :state="validationdes" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" style="margin-bottom: 1rem;">
              <b-col cols="12">
                <b-tabs vertical nav-wrapper-class="nav-vertical" fill class="tab-class">

<b-tab title="Dashboard Permissions">
    <b-table hover responsive :items="p_dashboard" :fields="fields">
        <template #cell(description)="data">
            <span>{{ data.item.description }}</span>
        </template>

        <template #cell()="data">
            <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
        </template>
    </b-table>
</b-tab>


<b-tab title="Client Permissions">
    <b-table hover responsive :items="p_client" :fields="fields">
        <template #cell(description)="data">
            <span>{{ data.item.description }}</span>
        </template>

        <template #cell()="data">
            <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
        </template>
    </b-table>
</b-tab>


<b-tab title="Contact Permissions">
    <b-table hover responsive :items="p_contact" :fields="fields">
        <template #cell(description)="data">
            <span>{{ data.item.description }}</span>
        </template>
        <template #cell()="data">
            <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
        </template>
    </b-table>
</b-tab>

<b-tab title="Todo Permissions">
    <b-table hover responsive :items="p_todo" :fields="fields">
        <template #cell(description)="data">
            <span>{{ data.item.description }}</span>
        </template>

        <template #cell()="data">
            <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
        </template>
    </b-table>
</b-tab>


<b-tab title="Files Permissions">
    <b-table hover responsive :items="p_files" :fields="fields">
        <template #cell(description)="data">
            <span>{{ data.item.description }}</span>
        </template>

        <template #cell()="data">
            <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
        </template>
    </b-table>
</b-tab>

<b-tab title="Projects Permissions">
    <b-table hover responsive :items="p_projects" :fields="fields">
        <template #cell(description)="data">
            <span>{{ data.item.description }}</span>
        </template>

        <template #cell()="data">
            <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
        </template>
    </b-table>
</b-tab>

<b-tab title="Leads Permissions">
    <b-table hover responsive :items="p_leads" :fields="fields">
        <template #cell(description)="data">
            <span>{{ data.item.description }}</span>
        </template>

        <template #cell()="data">
            <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
        </template>
    </b-table>
</b-tab>


<b-tab title="Portfolio Permissions">
    <b-table hover responsive :items="p_portfolio" :fields="fields">
        <template #cell(description)="data">
            <span>{{ data.item.description }}</span>
        </template>

        <template #cell()="data">
            <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
        </template>
    </b-table>
</b-tab>


<b-tab title="User Permissions">
    <b-table hover responsive :items="p_user" :fields="fields">
        <template #cell(description)="data">
            <span>{{ data.item.description }}</span>
        </template>

        <template #cell()="data">
            <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
        </template>
    </b-table>
</b-tab>
<b-tab title="Clockify Permissions">
    <b-table hover responsive :items="p_clockify" :fields="fields">
        <template #cell(description)="data">
            <span>{{ data.item.description }}</span>
        </template>

        <template #cell()="data">
            <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
        </template>
    </b-table>
</b-tab>
<b-tab title="Setting Permissions">
    <b-table hover responsive :items="p_setting" :fields="fields">
        <template #cell(description)="data">
            <span>{{ data.item.description }}</span>
        </template>

        <template #cell()="data">
            <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
        </template>
    </b-table>
</b-tab>
</b-tabs>
              </b-col>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-container>
  </b-modal>
</template>

<script>
import store from '@/store';
import Ripple from 'vue-ripple-directive';
import settingsStoreModule from '../../settingsStoreModule';
import { onUnmounted } from '@vue/composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import { BTabs, BTab, BCardText } from 'bootstrap-vue';
import { BTable } from 'bootstrap-vue';
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BTabs,
    BTab,
    BTable,
  },
  directives: {
    Ripple,
  },
  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';

    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },
  props: ['getRoles', 'loading'],
  data() {
    return {
      fields: [
        { key: 'Description', sortable: false, class: 'text-left w-75', thClass: 'bg-primary text-white' },
        { key: 'Yes/No', sortable: false, class: 'text-center w-25', thClass: 'bg-primary text-white' },
      ],

      required,
      selected: [],
      permissions: [],
      p_dashboard: [],
            p_client: [],
            p_contact: [],
            p_todo: [],
            p_files: [],
            p_projects: [],
            p_leads: [],
            p_portfolio: [],
            p_user: [],
            p_clockify: [],
            p_setting: [],

      role: {
        name: '',
        description: '',
        permissions: '',
      },
      submitted: false,
    };
  },
  methods: {
    submitForm() {
      const self = this;
      this.$emit('loading', true);
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.submitted = true;
          this.$bvModal.hide('addNewRole');
          this.role.permissions = this.selected;
          store.dispatch('settings/addRole', self.role).then((response) => {
            if (response.status == 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ Role Add Successfully',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
              this.getRoles();
              this.resetInfoModal();
            }
          }); // dispatch store action
        }
      });
    },
    getPermissions() {
      store
        .dispatch('settings/fetchPermissions', [])
        .then((res) => {
          this.permissions = res.data.data;
          res.data.data.forEach((p) => {
            p.group === "dashboard" ? this.p_dashboard.push(p) : "";
            p.group === "client" ? this.p_client.push(p) : "";
            p.group === "contact" ? this.p_contact.push(p) : "";
            p.group === "todo" ? this.p_todo.push(p) : "";
            p.group === "files" ? this.p_files.push(p) : "";
            p.group === "projects" ? this.p_projects.push(p) : "";
            p.group === "leads" ? this.p_leads.push(p) : "";
            p.group === "portfolio" ? this.p_portfolio.push(p) : "";
            p.group === "user" ? this.p_user.push(p) : "";
            p.group === "clockify" ? this.p_clockify.push(p) : "";
            p.group === "setting" ? this.p_setting.push(p) : "";

          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetInfoModal() {
      this.submitted = false;
      this.role.name = '';
      this.role.description = '';
      this.role.permissions = '';
      this.selected = [];
    },
    handleSelected(tr) {
      // this.$vs.notify({
      //   title: `Selected ${tr.username}`,
      //   text: `Email: ${tr.email}`
      // })
    },
  },
  computed: {
    validation() {
      return this.role.name.length > 1;
    },
    validationdes() {
      return this.role.description.length > 1;
    },
  },
  created() {
    this.getPermissions();
  },
};
</script>

<style scoped>
.tab-class >>> .nav-item {
  position: relative;
  display: flex;
  justify-content: start;
}

.tab-class >>> .nav-vertical {
  padding-left: 0 !important;
}

.tab-class >>> .nav-pills .nav-link.active {
  width: 100%;
}
</style>
