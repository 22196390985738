<template>
    <div>
        <b-card no-body class="mb-0">
            <b-overlay :show="loading" rounded="sm" no-fade>
                <div class="m-2">
                    <b-row>
                        <!-- Per Page -->
                        <b-col cols="12" md="7" class="d-flex align-items-center justify-content-start mb-1 mb-md-0"> </b-col>

                        <!-- Search -->
                        <b-col cols="12" md="5" v-if="$Can('settings_role_add')">
                            <div class="d-flex align-items-center justify-content-end">
                                <b-form-input v-model="filter" :clearable="true" class="d-inline-block mr-1" placeholder="Search..." />
                                <add-role ref="add-role" :getRoles="getRoles" :loading="loading"></add-role>
                                <b-button variant="primary" @click="addRole()">
                                    <span class="text-nowrap">Add New Role</span>
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </div>

                <b-table
                    striped
                    hover
                    class="position-relative"
                    :items="roles"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    responsive
                    :small="true"
                    head-variant="dark"
                    :fields="tableColumns"
                    show-empty
                    empty-text="No matching records found"
                    :current-page="currentPage"
                    :per-page="perPage"
                >
                    <!-- Column: Actions -->
                    <template #cell(actions)="row" v-if="$Can('settings_role_edit')">
                        <b-button v-if="row.item.name != 'supermanager'" size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1">
                            Edit Permissions
                        </b-button>
                    </template>

                    <template #row-details="row">
                        <b-card>
                            <ul>
                                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                            </ul>
                        </b-card>
                    </template>
                </b-table>
                <b-modal :id="infoModal.id" no-close-on-backdrop :title="infoModal.title" @hidden="resetInfoModal" scrollable size="xl" ok-title="Save" @ok="editPerms(role)">
                    {{ /*infoModal.content.permissions*/ }}
                    <b-container class="mt-1">
                        <b-form>
                            <b-row>
                                <b-col cols="12" style="margin-bottom: 1rem;">
                                    <b-col cols="12">
                                        <b-tabs vertical nav-wrapper-class="nav-vertical" fill class="tab-class">

                                            <b-tab title="Dashboard Permissions">
                                                <b-table hover responsive :items="p_dashboard" :fields="fields">
                                                    <template #cell(description)="data">
                                                        <span>{{ data.item.description }}</span>
                                                    </template>

                                                    <template #cell()="data">
                                                        <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                                                    </template>
                                                </b-table>
                                            </b-tab>

                                            
                                            <b-tab title="Client Permissions">
                                                <b-table hover responsive :items="p_client" :fields="fields">
                                                    <template #cell(description)="data">
                                                        <span>{{ data.item.description }}</span>
                                                    </template>

                                                    <template #cell()="data">
                                                        <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                                                    </template>
                                                </b-table>
                                            </b-tab>


                                            <b-tab title="Contact Permissions">
                                                <b-table hover responsive :items="p_contact" :fields="fields">
                                                    <template #cell(description)="data">
                                                        <span>{{ data.item.description }}</span>
                                                    </template>
                                                    <template #cell()="data">
                                                        <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                                                    </template>
                                                </b-table>
                                            </b-tab>

                                            <b-tab title="Todo Permissions">
                                                <b-table hover responsive :items="p_todo" :fields="fields">
                                                    <template #cell(description)="data">
                                                        <span>{{ data.item.description }}</span>
                                                    </template>

                                                    <template #cell()="data">
                                                        <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                                                    </template>
                                                </b-table>
                                            </b-tab>

                                            
                                            <b-tab title="Files Permissions">
                                                <b-table hover responsive :items="p_files" :fields="fields">
                                                    <template #cell(description)="data">
                                                        <span>{{ data.item.description }}</span>
                                                    </template>

                                                    <template #cell()="data">
                                                        <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                                                    </template>
                                                </b-table>
                                            </b-tab>

                                            <b-tab title="Projects Permissions">
                                                <b-table hover responsive :items="p_projects" :fields="fields">
                                                    <template #cell(description)="data">
                                                        <span>{{ data.item.description }}</span>
                                                    </template>

                                                    <template #cell()="data">
                                                        <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                                                    </template>
                                                </b-table>
                                            </b-tab>

                                            <b-tab title="Leads Permissions">
                                                <b-table hover responsive :items="p_leads" :fields="fields">
                                                    <template #cell(description)="data">
                                                        <span>{{ data.item.description }}</span>
                                                    </template>

                                                    <template #cell()="data">
                                                        <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                                                    </template>
                                                </b-table>
                                            </b-tab>


                                            <b-tab title="Portfolio Permissions">
                                                <b-table hover responsive :items="p_portfolio" :fields="fields">
                                                    <template #cell(description)="data">
                                                        <span>{{ data.item.description }}</span>
                                                    </template>

                                                    <template #cell()="data">
                                                        <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                                                    </template>
                                                </b-table>
                                            </b-tab>

                 
                                            <b-tab title="User Permissions">
                                                <b-table hover responsive :items="p_user" :fields="fields">
                                                    <template #cell(description)="data">
                                                        <span>{{ data.item.description }}</span>
                                                    </template>

                                                    <template #cell()="data">
                                                        <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                                                    </template>
                                                </b-table>
                                            </b-tab>
                                            <b-tab title="Clockify Permissions">
                                                <b-table hover responsive :items="p_clockify" :fields="fields">
                                                    <template #cell(description)="data">
                                                        <span>{{ data.item.description }}</span>
                                                    </template>

                                                    <template #cell()="data">
                                                        <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                                                    </template>
                                                </b-table>
                                            </b-tab>
                                            <b-tab title="Setting Permissions">
                                                <b-table hover responsive :items="p_setting" :fields="fields">
                                                    <template #cell(description)="data">
                                                        <span>{{ data.item.description }}</span>
                                                    </template>

                                                    <template #cell()="data">
                                                        <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                                                    </template>
                                                </b-table>
                                            </b-tab>
                                        </b-tabs>
                                    </b-col>
                                </b-col>
                            </b-row>
                        </b-form>
                    </b-container>
                    {{/*rolePerms*/}}
                </b-modal>
                <div class="mx-2 mb-2">
                    <b-row>
                        <b-col cols="9" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                        </b-col>

                        <!-- Pagination -->
                        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </b-overlay>
        </b-card>
    </div>
</template>

<script>
import axiosIns from "@/libs/axios";
import { BTable } from "bootstrap-vue";
import store from "@/store";
import settingsStoreModule from "../../settingsStoreModule";
import { onUnmounted } from "@vue/composition-api";
import AddRole from "./AddRole.vue";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BTabs, BTab, BCardText } from "bootstrap-vue";

export default {
    components: {
        BTable,

        AddRole,
        ToastificationContent,
        BCardText,
        BTabs,
        BTab,
    },

    setup() {
        const SETTINGS_APP_STORE_MODULE_NAME = "settings";

        // Register module
        if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
        });

        const toast = useToast();
    },

    data() {
        // { key: 'quoteDate', show: true, label: 'Quote Date', class: 'text-center', sortable: false, class: 'text-center', thStyle: 'width: 10%' },
        const tableColumns = [
            {
                key: "id",
                show: true,
                sortable: true,
                class: "text-center",
                thStyle: "width: 10%",
            },
            {
                key: "name",
                label: "Role Name",
                show: true,
                sortable: true,
                class: "text-center",
                thStyle: "width: 30%",
            },
            {
                key: "description",
                label: "Role Description",
                show: true,
                sortable: true,
                class: "text-center",
                thStyle: "width: 30%",
            },
            {
                key: "actions",
                show: true,
                sortable: false,
                class: "text-center",
                thStyle: "width: 30%",
            },
        ];

        return {
            rolePerms: [],
            edit: null,
            newitem: "",
            filter: null,
            filterOn: ["name"],
            perPage: 10,
            roles: [],
            permissions: [],
            currentPage: 1,
            perPageOptions: [5, 10, 15, 20],
            tableColumns,
            totalRows: 0,
            loading: false,
            p_dashboard: [],
            p_client: [],
            p_contact: [],
            p_todo: [],
            p_files: [],
            p_projects: [],
            p_leads: [],
            p_portfolio: [],
            p_user: [],
            p_clockify: [],
            p_setting: [],


            fields: [
                { key: "Description", sortable: false, class: "text-left w-75", thClass: "bg-primary text-white" },
                { key: "Yes/No", sortable: false, class: "text-center w-25", thClass: "bg-primary text-white" },
            ],
            infoModal: {
                id: "info-modal",
                title: "",
                content: "",
            },
        };
    },
    computed: {
        dataMeta() {
            return {
                // from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
                // to: perPage.value * (currentPage.value - 1) + localItemsCount,
                // of: totalUsers.value,
                from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
                to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
                of: this.totalRows,
            };
        },
    },
    methods: {
        addRole() {
            this.$root.$emit("bv::show::modal", "addNewRole");
        },
        onEdit(id, value) {
            this.edit = this.edit !== id ? id : this.editItem(id, value);
        },
        getRoles() {
            this.loading = true;
            store
                .dispatch("settings/fetchRoles", [])
                .then((res) => {
                    this.totalRows = res.data.data.length;
                    this.roles = res.data.data;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getPermissions() {
            this.loading = true;
            store
                .dispatch("settings/fetchPermissions", [])
                .then((res) => {
                    this.loading = false;
                    this.permissions = res.data.data;
                    res.data.data.forEach((p) => {
                        p.group === "dashboard" ? this.p_dashboard.push(p) : "";
                        p.group === "client" ? this.p_client.push(p) : "";
                        p.group === "contact" ? this.p_contact.push(p) : "";
                        p.group === "todo" ? this.p_todo.push(p) : "";
                        p.group === "files" ? this.p_files.push(p) : "";
                        p.group === "projects" ? this.p_projects.push(p) : "";
                        p.group === "leads" ? this.p_leads.push(p) : "";
                        p.group === "portfolio" ? this.p_portfolio.push(p) : "";
                        p.group === "user" ? this.p_user.push(p) : "";
                        p.group === "clockify" ? this.p_clockify.push(p) : "";
                        p.group === "setting" ? this.p_setting.push(p) : "";

                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        removeItem(event, id) {
            this.$swal({
                title: "Are you sure?",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    event.preventDefault();
                    axiosIns
                        .delete(`body/${id}`)
                        .then((res) => {
                            if (res.status == 200) {
                                this.getRoles();
                            }
                        })
                        .catch((error) => {
                            console.log("error delete ", error);
                        });
                }
            });
        },
        editItem(id, value) {
            if (value == "") {
                return;
            }
            axiosIns
                .put(`body/${id}`, {
                    value: value,
                })
                .then((res) => {
                    if (res.status == 200) {
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        editPerms(role) {
            this.loading = true;
            axiosIns
                .put(`roles/${role}`, {
                    permissions: JSON.parse(JSON.stringify(this.rolePerms)),
                })
                .then((response) => {
                    if (response.status == 200) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Successful",
                                text: "✔️ Permissions Updated Successfully",
                                icon: "ThumbsUpIcon",
                                variant: "success",
                            },
                        });
                        this.getRoles();
                    } else {
                        this.$swal({
                            title: "Error",
                            text: "Permissions Update Failed. Please try again or contact support.",
                            icon: "error",
                            showCancelButton: false,
                            confirmButtonText: "OK",
                        });
                    }
                });
        },
        info(item, index, button) {
            this.infoModal.title = `Edit Permissions For : ${item.name}`;
            this.infoModal.content = item;
            this.rolePerms = item.permissions;
            this.role = item.name;
            this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        },
        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
        },
    },
    created() {
        this.getRoles();
        this.getPermissions();
    },
};
</script>

<style scoped>
.tab-class >>> .nav-item {
    position: relative;
    display: flex;
    justify-content: start;
}

.tab-class >>> .nav-vertical {
    padding-left: 0 !important;
}

.tab-class >>> .nav-pills .nav-link.active {
    width: 100%;
}
</style>
